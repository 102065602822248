<template>
    <div style="background-color: #f5faff">
      <NavBar style="max-height: 50px;" :pageName="pageName" class="topNavBar"></NavBar>
  
      <v-app>
        <!-- Cancal appointment Dialog Start  -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="300">
              <v-card>
                <v-card-title style="word-break: normal; padding: 5%">
                  Are you sure you want cancel Appointment
                  <p style="font-weight: bold; margin: 0% 0% 0% 40%">
                    {{ book_id }}
                  </p>
                  ?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="confirm(appointmentid)">
                    Yes
                  </v-btn>
                  <v-btn color="error" @click="notConfirm"> No </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- start Confirmation for submission prescription -->
        <div>
          <v-row justify="center">
            <v-dialog
              v-model="presc_dialog"
              persistent
              max-width="500"
            >
              <v-card height="150">
                <v-card-title class="text-h5" style="word-break: break-word !important">
                  CONFIRMATION FOR SUBMISSION
                </v-card-title>
                <v-card-text>Once a prescription has been submitted, it cannot be changed.</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="presc_dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="save_prescription()"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
         <!-- end Confirmation for submission prescription -->
  
        <!-- start submission part for prescription -->
        <div>
          <v-row justify="center">
            <v-dialog
              v-model="save_dialog"
              persistent
              max-width="500"
            >
              <v-card height="120">
                <v-card-title class="text-h5">
                  Prescription Saved Successfully
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="saved_prescription()"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- end submission part for prescription -->
  
        <!-- Cancal appointment Dialog end  -->
  
        <!-- view Prescription Dialog Start  -->
        <div>
          <v-row>
            <v-dialog v-model="prescriptionDialog">
              <v-card style="padding: 40px 30px">
                <v-row>
                  <v-col cols="12">
                    <div style="display: flex; flex-direction: row; justify-content: space-between;  margin-top: 10px">
                      <div style="display: flex; flex-direction: row;">
                       <div> <p>Booking ID:</p></div>
                       <div><span style=" font-weight: 600">{{
                          appointment_reference_number
                        }}</span></div>
                      </div>
                      <div>
                         <v-btn
                          icon
                          color="red"
                          class="text--white"
                          v-on:click="closeDialog"
                        >
                          <h2>X</h2>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left" class="persistingSymptomsClass presentingSysmptoms">
                      Presenting Symptoms
                    </v-col>
                    <v-col cols="10" lg="10" sm="10" md="10" xl="10" class="persistingSymptomsClass presentingSysmptoms">
                      <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="presenting_symptoms" solo rows=1></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Past medical history
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="medical_history" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Past surgical history
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="surgical_history" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Family history
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="family_history" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Regular taking/ Current medicine
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="current_taking_medicines" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Known Drug Allergies
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="allergies" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                    Vaccination History
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="vaccination_history" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                    Diagnosis<span style="color:red">*</span>
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="diagnosed_with" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3" md="3" align="left" class="docViewAppointmentMedicalDetails">
                    Treatment
                  </v-col>
                  <v-col cols="12" sm="12" md="12" align="left">
                    <table style=" width: 100%; background-color: #F1F6FF;" class="prescriptionTableClass">
                      <tr align="left" style="padding: 15px;">
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</td>
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</td>
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</td>
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Duration</td>
                      </tr>
                      <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                        <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined
                          :disabled="disable_field" v-model="item.medicine"/></td>
                        <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined
                          :disabled="disable_field" v-model="item.dosage"/></td>
                        <td style="padding:5px;"><v-select class="textfield_class" solo outlined :items="frequency_list"
                          :disabled="disable_field" v-model="item.frequency"/></td>
                         <td style="padding:5px;"><v-text-field class="textfield_class" solo outlined
                          :disabled="disable_field" v-model="item.duration"/></td>
                        <td><img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                          <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"></td>
                      </tr>
                    </table>
  
                    <table style=" width: 100%; background-color: #F1F6FF; margin-top:18px" class="PrescriptionTableMobileLayout" v-for="(item,index) in prescription_object" :key=index>
                        <tr align="left">
                          <td style="font-size:12px; line-height: 20px; padding: 5px;">Medicine</td>
                        </tr>
                        <tr >
                          <td style="padding:5px;"><v-text-field class="textfield_class" :disabled="disable_field" solo outlined
                           v-model="item.medicine"/></td>
                        <tr>
                        <table >
                        <tr>
                          <td style="font-size:12px; line-height: 20px; padding: 5px;">Dosage</td>
                          <td style="font-size:12px; line-height: 20px; padding: 5px;">Frequency</td>
                          <td style="font-size:12px; line-height: 20px; padding: 5px;">Duration</td>
                        </tr>
                        <tr >
                          <td style="padding:5px;"><v-text-field class="textfield_class" :disabled="disable_field" solo outlined
                           v-model="item.dosage"/></td>
                          <td style="padding:5px;"><v-text-field class="textfield_class" :disabled="disable_field" solo outlined
                           v-model="item.frequency"/></td>
                          <td style="padding:5px;"><v-text-field class="textfield_class" :disabled="disable_field" solo outlined
                           v-model="item.duration"/></td>
                        </tr>
                           </table>
                        </tr>
                        </tr>
                        <tr>
                          <td>
                            <!-- <img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                            <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"> -->
                            <v-btn width="100%" color="primary" v-if="index == prescription_object.length-1" @click="add_rec" >
                              + Add More
                            </v-btn>
                            <v-btn width="100%" color="warning" v-else-if="index < prescription_object.length" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)" >
                              Delete Row
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                  </v-col>
                </v-row>
                 <v-row>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left" class="docViewAppointmentMedicalDetails">
                   Suggestions
                  </v-col>
                  <v-col cols="12" lg="12" sm="12" md="12" xl="12" class="docViewAppointmentMedicalDetails">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="suggestions" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left" class="docViewAppointmentMedicalDetails">
                    Investigations needed
                  </v-col>
                  <v-col cols="12" lg="12" sm="12" md="12" xl="12" class="docViewAppointmentMedicalDetails">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="investigations_needed" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails" style="padding-left: 12px;">
                    To Come after
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docViewAppointmentMedicalDetails">
                    <!-- <input type="date" v-model="followUpDate" background-color="#F1F6FF"/> -->
                    <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="followUpDate"
                        :disabled="disable_field"
                        label="Follow Up Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="followUpDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                    Refer To
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docViewAppointmentMedicalDetails">
                    <v-select background-color="#F1F6FF" solo :disabled="disable_field" v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/>
                  </v-col>
                  <v-col lg="7" sm="7" md="7" xl="7" v-if="selected_specialization.specialization_name == 'Others'">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="disable_field" v-model="others" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn color = primary v-show="!disable_field" @click="submit_prescription()" > Update Prescription </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <!-- view Prescription Dialog end  -->
        <div style="background-color: #f5faff;">
           <v-row cols="12">
          <v-col cols="2">
          <SideBar style="max-height: 50px;" :pageName="pageName" class="sideBar"></SideBar>
          </v-col>
            <v-col cols="10" style="overflow:scroll; height:97vh">
              <div class="contentMainDiv">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    vertical-align: middle;">
                  <div  align="left" class="pageTitleClass">
                    <span style="font-weight: 800; padding-left: 5px">
                      <span
                        style="
                          font-weight: 800;
                          padding-left: 5px;
                          margin-top: 10px;
                          font-size: 12pt;
                          text-transform: uppercase;
                          letter-spacing: 1px;
                        "
                        >Appointment History</span
                      >
                    </span>
                  </div>
                  <!-- <div class="homeClass" align="right">
                    <v-btn
                      style="margin-top:3px; background-color:#FFFFFF;"
                      @click="hisClick"
                    >
                    <img style="padding-right:5px; " src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/leftpointer.svg"/>
                    <span
                      style="
                        font-weight: 600;
                        padding-left: 0px;
                        padding-right: 5px;
                        padding-top:10px;
                        padding-bottom:10px;
                        margin-top: 0px;
                        font-size: 10pt;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                      "
                      >Home
                      </span>
                    </v-btn>
                  </div> -->
                  <div style="display: flex" class="searchClass">
                    <div
                      style="display: flex; flex-direction: row"
                      v-if="search_type == 'Date'"
                    >
                      <div>
                        <p style="margin-top: 18px; font-weight: bold">From:</p>
                      </div>
                      <div>
                        <input
                          type="date"
                          v-model="start_date"
                          @change="(phone_number = ''), (nrc_number = '')"
                          style="
                            margin-top: 7%;
                            background-color: white;
                            width: 90%;
                            padding: 3%;
                            box-shadow: 0px 8px 30px 0px #0547891a;
                            color: #1467bf;
                            border-radius: 5px;
                          "
                        />
                      </div>
                    </div>
                    <div
                      style="display: flex; flex-direction: row"
                      v-if="search_type == 'Date'"
                    >
                      <div>
                        <p style="margin-top: 18px; font-weight: bold">To:</p>
                      </div>
                      <div>
                        <input
                          type="date"
                          v-model="end_date"
                          style="
                            margin-top: 7%;
                            background-color: white;
                            width: 90%;
                            padding: 3%;
                            box-shadow: 0px 8px 30px 0px #0547891a;
                            color: #1467bf;
                            border-radius: 5px;
                          "
                          @change="(phone_number = ''), (nrc_number = '')"
                        />
                      </div>
                      <div v-if="start_date && end_date">
                        <a>
                          <img
                            @click="filterWithDateRange"
                            style="margin-top: 14px; height: 2em"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg"
                        /></a>
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: flex-end;
                        margin: 10px 5px;
                        margin-top: 1%;
                      "
                    >
                      <v-select
                        v-model="search_type"
                        :items="items"
                        placeholder="Search With"
                        solo
                        dense
                        @change="
                          (start_date = ''),
                            (end_date = ''),
                            (phone_number = ''),
                            (nrc_number = '')
                        "
                        style="width: 30%; margin-right: 5px"
                      ></v-select>
  
                      <!-- <v-text-field
                        v-if="search_type == 'NRC'"
                        v-model="nrc_number"
                        label="Enter NRC number"
                        dense
                        solo
                        style="width: 50%"
                      >
                        <template v-slot:append>
                          <v-btn @click="filterAppointment" icon>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                          </v-btn>
                        </template>
                      </v-text-field> -->
  
                      <v-text-field
                        v-if="search_type == 'Phone Number'"
                        v-model="phone_number"
                        placeholder="Type here"
                        label="Enter Phone number"
                        :rules="mobileNumberRules"
                        dense
                        solo
                        style="width: 50%"
                        ><template v-slot:append>
                          <v-btn @click="filterAppointment" icon>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                          </v-btn>
                        </template>
                      </v-text-field>
  
                      <v-text-field
                        v-else-if="search_type != ''"
                        v-show="false"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="tableDiv">
                  <div
                  style="margin-top: 50px; height: 500px"
                  v-if="!isLoading"
                  >
                    <table
                      id="appointments_table"
                      class="tableClass"
                      v-if="allAppointments.length > 0"
                    >
                      <tr>
                        <th align="left" style="padding-left:8px;">Booking ID</th>
                        <th align="left">Patient Gender</th>
                        <th align="left">Birth Year</th>

                        <th align="left">Date of Appointment</th>
                        <th align="left">Time Slot</th>
  
                      </tr>
  
                      <tr
                        v-for="appointment in allAppointments"
                        :key="appointment._id"
                      >
                        <td align="left">
                          {{ appointment.appointment_reference_number }}
                        </td>
                        <td align="left">
                          {{ appointment.customer_gender }}
                        </td>
                        <td align="left">
                          {{ appointment.birth_year }}
                        </td>
               
                        <td align="left">
                          <DateRendered :dateVal="appointment.date_of_appointment">
                          </DateRendered>
                        </td>
                        <td align="left">
                          <TimeRender :timeSlot="appointment.time_slot"> </TimeRender>
                        </td>

                        </tr>
                    </table>
  
                    <div v-else>
                      <p>Sorry, No matching results found.</p>
                    </div>
                    <div v-if="pagination_list > 1">
                      <v-pagination
                      circle
                      v-model="page"
                      :length="pagination_list"
                      :total-visible="7"
                      @input="findPage(page)"
                      ></v-pagination>
                    </div>
                  </div>
  
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-else
                  ></v-progress-circular>
                </div>
  
                <div class="appointmentHistoryCard">
                  <div class="responsiveSearch d-flex">
                    <div class="d-flex">
                      <div style="width: 50%">
                        <p class="pa-0 ma-0 responsiveSearchWithtext">Search With</p>
                      </div>
                      <div>
                        <v-select
                        class="responsiveSearchSelect ma-0 pa-0"
                        v-model="search_type"
                        :items="items"
                        placeholder="Search With"
                        solo flat
                        dense
                        @change="(start_date = ''),(end_date = ''), (phone_number = ''),(nrc_number = '')"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div v-if="search_type === 'Phone Number'">
                      <v-text-field
                      v-model="phone_number"
                      type="number"
                      placeholder="Type Number"
                      flat
                      solo>
                      <template v-slot:append>
                        <img width="40" @click="filterAppointment" src="https://s3iconimages.mymedicine.com.mm/search.svg">
                      </template>
                      </v-text-field>
                    </div>
                    <div v-if="search_type === 'Date'">
                      <div class="d-flex fromDateSearch">
                        <div style="width: 25%">
                          <p class="pa-0 ma-0 responsiveSearchWithtext">From :</p>
                        </div>
                        <div>
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_date"
                              label="Select From Date"
                              readonly
                              solo flat
                              v-bind="attrs"
                              v-on="on"
                              class="textFieldForDate rounded-lg"
                            >
                            <template v-slot:append>
                              <img width="30" @click="menu=!menu" src="https://s3iconimages.mymedicine.com.mm/calendar.svg">
                            </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                          v-model="start_date"
                          no-title
                          :max="new Date().toISOString().substr(0, 10)"
                          scrollable
                          class="datePickerClass"
                          @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
  
                        </div>
                      </div>
                      <div class="d-flex fromDateSearch">
                        <div style="width: 25%">
                          <p class="pa-0 ma-0 responsiveSearchWithtext">To :</p>
                        </div>
                          <div>
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="end_date"
                                label="Select End Date"
                                readonly
                                solo flat
                                v-bind="attrs"
                                v-on="on"
                                class="textFieldForDate rounded-lg mb-1"
                              >
                              <template v-slot:append>
                                <img width="30" @click="menu=!menu" src="https://s3iconimages.mymedicine.com.mm/calendar.svg">
                              </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                            v-model="end_date"
                            no-title
                            :max="new Date().toISOString().substr(0, 10)"
                            scrollable
                            class="datePickerClass"
                            @input="menu2 = false, filterWithDateRange()"
                            >
                            </v-date-picker>
                            </v-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!isLoading">
                  <div v-for="appointment in allAppointments"  :key="appointment._id">
                    <appointmentHistoryCard :appointmentData="appointment" class="appointmentHistoryCard" @openPrescription ="viewParticularPrescription"></appointmentHistoryCard>
                  </div>
                    <div v-if="pagination_list > 1">
                      <v-pagination
                      circle
                      v-model="page"
                      :length="pagination_list"
                      :total-visible="7"
                      @input="findPage(page)"
                      ></v-pagination>
                    </div>
                  </div>
                  <div v-else>
                    <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-app>
      <bottomNavBar :pageName = "value" class="bottomNavBar"></bottomNavBar>
    </div>
  </template>
  
  
  <script>
  import axios from "axios";
  import NavBar from "../../../views/navbar.vue";
  import moment from "moment";
  import DateRendered from "../Customer/dateRender.vue";
  import TimeRender from '../Customer/TimeRender.vue';
  import SideBar from "../../../views/sideBar.vue";
  import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
  import { handleError } from '../../../utils/utils';
  
  export default {
    name: "AppointmentHistory",
    data() {
      return {
        value:'AppointmentHistory',
        start_date: "",
        end_date: "",
        presc_dialog: false,
        save_dialog: false,
        allAppointments: "",
        pageName: "",
        page:1,
        currentUser: "",
        currentUserName: "",
        customer_name: "",
        customer_id: "",
        specialization: "",
        date_of_appointment: "",
        pageNum: "",
        items: ["Phone Number", "Date"],
        nrc_number: "",
        phone_number: "",
        search_type: "",
        dialog: false,
        viewAppointmentDialog: false,
        appointmentid: "",
        book_id: "",
        nominee: "",
        enquiry_name: "",
        phoneNumberofPatient: "",
        amount: "",
        nrcNumber: "",
        nameOfDoctor: "",
        specializationOfDoctor: "",
        payment_status: "",
        time_slot: "",
        date: "",
        description: "",
        mobileNumberRules: [
          (v) => !!v || "Mobile Number is required",
          (v) => v > 0 || "Mobile number cannot be negative",
        ],
        isLoading: true,
        pagination_list: "",
        prescriptionDialog: false,
        prescriptionID: "",
        appointment_reference_number: "",
        age: "",
        prescriptionId: "",
        particularPrescriptionDetails: "",
        unstable_connectivity: false,
        patient_no_show: false,
        referred_to_specialist: false,
        need_follow_up: false,
        exams: "",
        suggestions: "",
        current_taking_medicines: "",
        diagnosed_with: "",
        allergies: "",
        selected: [],
        disable_field: false,
        current_phone_number: "",
        current_start_date: "",
        current_end_date: "",
        break_here_for_newtemplate_variable: "",
        presenting_symptoms: "",
        medical_history: "",
        surgical_history: "",
        family_history: "",
        current_taking_medicines: "",
        allergies: "",
        vaccination_history: "",
        diagnosed_with: "",
        investigations_needed: "",
        prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
        frequency_list: ["OD","CM","HS","BD","TDS","QID","PRN"],
        follow_up_list: ["One Week", "Two week", "One Month", "Two Month","Three Month","Not Required"],
        come_after: "",
        others: "",
        specialization_list: [],
        selected_specialization: "",
        followUpDate:"",
        menu:false,
        menu2: false,
      };
    },
    mounted() {
      document.title = "Appointment History"
      this.pageName = "AppointmentHistory";
      this.currentUser = this.$cookies.get("doctorToken");
      if (!this.currentUser) {
        this.$router.push({
          name: "DoctorLogin",
        });
      } else {
        var backDate = new Date();
        backDate.setDate(backDate.getDate() - 30);
        this.start_date = backDate.toISOString().substr(0, 10);
        this.end_date = new Date().toISOString().substr(0, 10);
        var data = {
          token : this.currentUser,
          typeOfUser : "DOCTOR",
          fromDate: this.start_date,
          toDate: this.end_date,
          // doctorId: this.doctor_id,
          page: 1,
          limit: 10,
        };
        axios_auth_instance_doctor.post("/doctorAppointmentHistoryPageCPI", data)
        .then((successResponse) => {
          this.currentUserName = successResponse.data.doctor_data.doctor_name;
          this.doctor_id = successResponse.data.doctor_data._id;
  
          this.allAppointments = successResponse.data.appointments_data.docs;
          // var newDae = new Date(this.allAppointments[0].date_of_appointment)
          // console.log(newDae.getDate());
          this.pagination_list = successResponse.data.appointments_data.pages;
          this.isLoading = false;
          this.current_start_date = this.start_date;
          this.current_end_date = this.end_date;
          this.current_phone_number = this.phone_number;
        })
        .catch((errorResponse) => {
          handleError(errorResponse, this.$router, 'doctorAppointmentHistoryPage', 'DoctorLogin', 'doctorToken');
        });
      }
    },
    components: {
      NavBar,
      DateRendered,
      TimeRender,
      SideBar,
      bottomNavBar: () => import('../../../components/docBottomNavBar.vue'),
      appointmentHistoryCard: () => import('../../../components/appointmentHistoryCard.vue'),
    },
    methods: {
      add_rec() {
        this.prescription_object.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
      },
      remove_rec(medicine, unit, dosage) {
        this.prescription_object.forEach(function (item, index, object) {
          if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration)
          {
            object.splice(index, 1);
          }
        });
      },
      hisClick(){
        this.$router.push({
          name:"DoctorHome"
        });
      },
      submit_prescription(){
        this.presc_dialog = true;
      },
      saved_prescription(){
        this.save_dialog = false;
        this.$router.go();
      },
      save_prescription() {
        if(this.diagnosed_with)
        {
          var DateInDateformat = new Date(this.date_of_appointment)
          if(this.come_after == "One Week"){
            DateInDateformat.setDate(DateInDateformat.getDate()+7)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
          else if(this.come_after == "Two Week"){
             DateInDateformat.setDate(DateInDateformat.getDate()+14)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
           else if(this.come_after == "One Month"){
              DateInDateformat.setMonth(DateInDateformat.getMonth()+1)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
  
          }
           else if(this.come_after == "Two Month"){
              DateInDateformat.setMonth(DateInDateformat.getMonth()+2)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
           else if(this.come_after == "Three Month"){
              DateInDateformat.setMonth(DateInDateformat.getMonth()+3)
            this.followUpDate = DateInDateformat.toISOString().substr(0,10);
          }
          var presc_body = {
            covidRelatedSymptoms: this.selected,
            allergies: this.allergies,
            diagnosedWith: this.diagnosed_with,
            currentMedication: this.current_taking_medicines,
            suggestion: this.suggestions,
            examinationTBD: this.exams,
            followUp: this.need_follow_up,
            patientNoShow: this.patient_no_show,
            referredSpecialist: this.referred_to_specialist,
            unstableConnection: this.unstable_connectivity,
            appointmentReferenceNo: this.appointment_reference_number,
            doctorName: this.currentUserName,
            doctorId: this.doctor_id,
            customerName: this.customer_name,
            customerId: this.customer_id,
            appointmentDate: this.date_of_appointment,
            specialization: this.specialization,
            break_here_for_newtemplate_variable: "",
            presentSymptoms: this.presenting_symptoms,
            medicalHistory: this.medical_history,
            surgicalHistory: this.surgical_history,
            familyHistory: this.family_history,
            currentMedication: this.current_taking_medicines,
            allergies: this.allergies,
            vaccinationHistory: this.vaccination_history,
            diagnosedWith: this.diagnosed_with,
            investigationNeeded: this.investigations_needed,
            comeAfter: this.come_after ? this.come_after : "Not Required",
            followUpDate: this.followUpDate ? this.followUpDate : "",
            referredSpecialistName: this.selected_specialization.specialization_name != 'Others' ? this.selected_specialization.specialization_name : this.others,
            token : this.currentUser,
            typeOfUser : "DOCTOR"
          }
          this.prescription_object.forEach(function(item, index, object) {
            if (item.medicine === '') {
              object.splice(index, 1);
            }
          });
          if(this.prescription_object.length == 0)
            presc_body.prescription = [];
          else if(this.prescription_object.length > 0)
            presc_body.prescription = this.prescription_object;
          axios.post(process.env.VUE_APP_BACKEND_URL+"/createPrescription",presc_body)
          .then((createPrescriptionResponse) =>{
            this.presc_dialog = false;
            this.prescriptionDialog = false;
            this.save_dialog = true;
          })
          .catch((createPrescriptionError) =>{
          });
        }
        else
        {
          window.alert("Diagnosis is mandatory");
        }
      },
      viewParticularPrescription(prescriptionReferenceNumber, appointment_reference_number,customerName, customerId,specialization_name,date_of_appointment) {
        this.prescriptionDialog = true;
        this.customer_name = customerName;
        this.customer_id = customerId;
        this.specialization = specialization_name;
        this.date_of_appointment = date_of_appointment;
        var presc_dets = "";
        var getPrescriptionData = {
          token: this.currentUser,
          typeOfUser: "DOCTOR",
          prescriptionId: prescriptionReferenceNumber,
        };
        if (prescriptionReferenceNumber) {
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/readParticularPrescription",
              getPrescriptionData
            )
            .then((getPrescriptionDataResponse) => {
              this.particularPrescriptionDetails = getPrescriptionDataResponse.data.data;
              this.appointment_reference_number = this.particularPrescriptionDetails.appointment_reference_number;
              presc_dets = getPrescriptionDataResponse.data.data;
              this.selected = presc_dets['covid_related_symptoms'];
              this.allergies = presc_dets['allergies'];
              this.diagnosed_with = presc_dets['diagnosed_with'];
              this.disable_field = true;
              this.current_taking_medicines = presc_dets['current_taking_medicines'];
              this.suggestions = presc_dets['suggestion'];
              this.allergies = presc_dets['allergies'];
              if(presc_dets['need_follow_up'] == true)
                this.need_follow_up = "true"
              this.exams = presc_dets['examination_to_be_done'];
              if(presc_dets['patient_no_show'] == true)
                this.patient_no_show = presc_dets['patient_no_show'];
              if(presc_dets['referred_to_specialist'] == true)
                this.referred_to_specialist = "true";
              if(presc_dets['unstable_connectivity'] == true)
              {
                this.unstable_connectivity = "true";
              }
              if(presc_dets['prescription'].length > 0)
                this.prescription_object = presc_dets['prescription'];
              this.presenting_symptoms = presc_dets.presentSymptoms;
              this.medical_history = presc_dets.medicalHistory;
              this.surgical_history = presc_dets.surgicalHistory;
              this.family_history = presc_dets.familyHistory;
              this.vaccination_history = presc_dets.vaccinationHistory;
              this.investigations_needed = presc_dets.investigationNeeded;
              this.followUpDate = presc_dets.next_followup_date;
              axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", getPrescriptionData)
              .then((readAllSpecializationsResponse) => {
                var spec_list = readAllSpecializationsResponse.data.data;
                this.specialization_list.push({'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"});
                this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
                spec_list.forEach((x) =>{
                  this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
                });
                let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
                if(obj)
                {
                  this.selected_specialization = obj;
                }
                else if(prescriptionReferenceNumber)
                {
                  this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
                  this.others = presc_dets.referredSpecialistName;
                }
                else
                {
                  this.selected_specialization = "";
                  this.others = "";
                }
              })
              .catch((readAllSpecializationsError) =>{
              });
            })
            .catch((getPrescriptionDataError) => {
              if (getPrescriptionDataError.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$router.push({
                  name: "DoctorLogin",
                });
              } else {
                window.alert("Something went wrong...");
              }
            });
        } else {
          this.disable_field = false;
          this.appointment_reference_number = appointment_reference_number;
          this.prescriptionID= "";
          this.age="";
          this.prescriptionId= "";
          this.particularPrescriptionDetails= "";
          this.unstable_connectivity=false;
          this.patient_no_show= false;
          this.referred_to_specialist=false;
          this.need_follow_up= false;
          this.exams= "";
          this.suggestions= "";
          this.prescription_object= [{ medicine: "", dosage: "", frequency: "", duration: "" }];
          this.current_taking_medicines= "";
          this.diagnosed_with= "";
          this.allergies="";
          this.selected= [];
          this.presenting_symptoms = "";
          this.medical_history = "";
          this.surgical_history = "";
          this.family_history = "";
          this.vaccination_history = "";
          this.investigations_needed = "";
          this.come_after = "";
          axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", getPrescriptionData)
          .then((readAllSpecializationsResponse) => {
            var spec_list = readAllSpecializationsResponse.data.data;
            this.specialization_list.push({'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"});
            this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
            spec_list.forEach((x) =>{
              this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
            });
            let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
            if(obj)
            {
              this.selected_specialization = obj;
            }
            else if(prescriptionReferenceNumber)
            {
              this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
              this.others = presc_dets.referredSpecialistName;
            }
            else
            {
              this.disable_fiels = "";
              this.selected_specialization = "";
              this.others = "";
            }
          })
          .catch((readAllSpecializationsError) =>{
          });
        }
      },
      findPage(pageNum) {
        this.isLoading = true;
        var getPageData = {
          doctorId: this.doctor_id,
          fromDate: this.current_start_date,
          toDate: this.current_end_date,
          token: this.currentUser,
          phoneNumber: this.current_phone_number,
          typeOfUser: "DOCTOR",
          page: pageNum,
          limit: 10,
        };
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/getCompletedDoctorAppointments",
            getPageData
          )
          .then((getPatientListResponse) => {
            this.allAppointments = getPatientListResponse.data.data;
            this.current_start_date = this.current_start_date;
            this.current_end_date = this.current_end_date;
            this.current_phone_number = this.current_phone_number;
            this.pagination_list = getPatientListResponse.data.pages;
            this.isLoading = false;
          })
          .catch((getPatientListError) => {
            if (getPatientListError.response.status == 401) {
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.push({
                name: "DoctorLogin",
              });
            } else {
              window.alert("Something went wrong...");
            }
            this.isLoading = false;
          });
      },
      filterWithDateRange() {
        if (
          moment(this.start_date).format("YYYY-MM-DD") >
          moment(new Date()).format("YYYY-MM-DD") ||
          moment(this.end_date).format("YYYY-MM-DD") >
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          alert("Please enter past dates only");
        } else {
          if (
            moment(this.start_date).format("YYYY-MM-DD") >
            moment(this.end_date).format("YYYY-MM-DD")
          ) {
            alert("Please enter valid date range");
          } else {
            this.isLoading = true;
            var appointment_body = {
              doctorId: this.doctor_id,
              fromDate: this.start_date,
              toDate: this.end_date,
              token: this.currentUser,
              typeOfUser: "DOCTOR",
              page: 1,
              limit: 10,
            };
            axios
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  "/getCompletedDoctorAppointments",
                appointment_body
              )
              .then((viewCurrentDateResponse) => {
                this.allAppointments = viewCurrentDateResponse.data.data;
                this.pagination_list = viewCurrentDateResponse.data.pages;
                this.isLoading = false;
                this.current_start_date = this.start_date;
                this.current_end_date = this.end_date;
                this.current_phone_number = this.phone_number;
                this.page = 1;
              })
              .catch((viewCurrentDateError) => {
                console.log('viewCurrentDateError', viewCurrentDateError)
                if (viewCurrentDateError.response.status == 401) {
                  window.alert("Sorry !! You are unauthorized !!");
                  this.$router.push({
                    name: "DoctorLogin",
                  });
                } else {
                  window.alert("Something went wrong...");
                }
              });
          }
        }
      },
      closeDialog() {
        this.viewAppointmentDialog = false;
        this.prescriptionDialog = false;
      },
      viewParticularAppointment(booking_id) {
        this.booking_id = booking_id;
        let routeData = this.$router.push({
          path: "/doctor/viewappointment/" + booking_id,
        });
      },
      notConfirm() {
        this.dialog = false;
      },
      confirm(appointment_id) {
        this.dialog = false;
        var appointment_body = {
          appointmentId: this.appointmentid,
          token: this.currentUser,
          typeOfUser: "DOCTOR",
        };
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/cancelAppointment",
            appointment_body
          )
          .then((viewAppointmentsResponse) => {
            alert("Appointment Cancelled");
            this.$router.go();
          })
          .catch((viewAppointmentsError) => {
          });
      },
      editAppointment(appointment_id) {
        this.$router.push({
          path: "viewAppointment/" + appointment_id,
        });
      },
      filterAppointment() {
        if (this.nrc_number != "" || this.phone_number != "") {
          this.isLoading = true;
          var appointmentSearch = {
            token: this.currentUser,
            doctorId: this.doctor_id,
            typeOfUser: "DOCTOR",
            phoneNumber: this.phone_number,
            page: 1,
            limit: 10,
          };
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/getCompletedDoctorAppointments",
              appointmentSearch
            )
            .then((viewCurrentDateResponse) => {
              this.allAppointments = viewCurrentDateResponse.data.data;
              this.pagination_list = viewCurrentDateResponse.data.pages;
              this.current_start_date = this.start_date;
              this.current_end_date = this.end_date;
              this.current_phone_number = this.phone_number;
              this.start_date = "";
              this.end_date = "";
              this.page = 1;
              this.isLoading = false;
            })
            .catch((viewCurrentDateError) => {
              if (viewCurrentDateError.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$router.push({
                  name: "DoctorLogin",
                });
              } else {
                window.alert("Something went wrong...");
                this.isLoading = false;
                this.allAppointments = [];
                this.pagination_list = 0;
              }
            });
        } else {
          window.alert("Search Field can't empty!");
          this.isLoading = false;
          this.allAppointments = [];
          this.pagination_list = 0;
        }
      },
      rescheduleAppointment(appointment_id) {
        this.$router.push({
          path: "rescheduleAppointment/" + appointment_id,
        });
      },
      cancelAppointment(appointment_id, appointment_reference_number) {
        this.dialog = true;
        this.appointmentid = appointment_id;
        this.book_id = appointment_reference_number;
      },
    },
  };
  </script>
  
  <style scoped>
  td,
  th {
    border: 0px;
  }
  td {
    padding: 5px;
    font-size: 10pt;
    font-weight: 500;
  }
  th {
    background-color: #1467bf;
    color: white;
    border: 0px;
    font-size: 8pt;
    font-weight: 500;
    margin-left: 10%;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 40px;
  }
  tr {
    border-bottom: #d4eeff 1px solid !important;
  }
  .tableClass {
    border-spacing: 0px;
    width: 100%;
    border-collapse: collapse;
    z-index: 0;
    background-color: white;
  }
  table > tr > td:last-child {
    white-space: nowrap;
  }
  .bottomNavBar {
      display: none;
      position: fixed;
      bottom: 0;
      width:  100%;
    }
  .contentMainDiv{
    margin: 32px 20px 20px 50px;
  }
  .tableDiv{
    display: block;
  }
  .appointmentHistoryCard{
    display: none;
  }
  
  .PrescriptionTableMobileLayout {
    display: none !important;
  }
  @media (min-width: 320px) and (max-width: 1024px){
    .bottomNavBar {
      display: block;
    }
    .topNavBar,.sideBar,.searchClass {
      display: none !important;
    }
    .col-2, .col-10{
      flex: unset !important;
      max-width: unset !important;
    }
    .col-2{
      padding: unset !important;
    }
    .contentMainDiv{
    margin: 20px 18px !important;
  }
  .tableDiv{
    display: none;
  }
  .appointmentHistoryCard{
    display: block;
    margin-top: 8px !important;
  }
  .responsiveSearch{
    flex-direction: column;
  }
  .responsiveSearchWithtext{
    margin-top: 10px !important;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .fromDateSearch{
    justify-content: space-between;
    margin-top: 4px;
  }
  .textFieldForDate{
    margin-bottom: 4px !important;
    border: 1px solid #E4F5FF;
  }
  .persistingSymptomsClass{
        max-width: unset;
        flex: unset;
        padding:unset;
  }
  .presentingSysmptoms{
    padding-left:12px ;
  }
  .docViewAppointmentMedicalDetails{
          max-width: unset;
          flex: unset;
          text-align: left;
  }
  .prescriptionTableClass{
    display: none !important;
  }
  .PrescriptionTableMobileLayout {
    display: block !important;
  }
  .mobilePaginationbutton{
    margin-top: 8px !important;
    /* justify-content: space-evenly; */
  }
   }
  </style>
  <style>
  .responsiveSearchSelect.v-text-field.v-text-field--enclosed .v-text-field__details{
    display: none !important;
  }
  .textFieldForDate .v-text-field__details{
    display: none !important;
  }
  .textFieldForDate .v-input__slot{
    margin-bottom: unset !important;
  }
  .datePickerClass .v-picker__body.v-picker__body--no-title.theme--light{
    width: unset !important;
  }
  </style>
  